import React from 'react';
import { saveAs } from 'file-saver';
import { Button } from '@mui/material';
import { Download } from '@mui/icons-material';

const ImageDownloader = ({ imageUrl }) => {
  // const downloadImage = async () => {
  //   try {
  //     const response = await fetch(imageUrl);
  //     const blob = await response.blob();
  //     const fileName = imageUrl.split('/').pop();
  //     saveAs(blob, fileName);
  //   } catch (error) {
  //     console.error('Error downloading the image', error);
  //   }
  // };
  const downloadImage = async () => {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = imageUrl.split('/').pop();
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error downloading the image', error);
    }
  };

  return (
    <Button color='success' variant='contained' onClick={downloadImage}><Download></Download>Download</Button>
  );
};

export default ImageDownloader;
