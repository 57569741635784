import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Input, TextField } from '@mui/material';
import { ArrowBack, CopyAll, Download, RemoveRedEye } from '@mui/icons-material';
import DownloadButton from './DownloadButton';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  p: 4,
};

export default function ModalView({modalOpen,setModalOpen,viewID,children}) {

  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);

  return (
    <div>
      <Modal
        open={modalOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{borderRadius: '15px'}}
      >
        <Box sx={style}>
        <Button onClick={(e)=>{window.location.href='/'}}><ArrowBack></ArrowBack> Home</Button>
        <Typography id="modal-modal-title" variant="h6" component="h2">
            Your Swap is Ready 🎉
          </Typography>
          <Box 
            sx={{ 
              display: 'flex', 
              justifyContent: 'center', 
              alignItems: 'center', 
              mt: 2,
              mb: 2 
            }}
          >
            <img src='/beast-arabic-kuth.gif' width='50px' alt="swap image" />
            <img src='/beast-arabic-kuth.gif' width='50px' alt="swap image" />
            <img src='/beast-arabic-kuth.gif' width='50px' alt="swap image" />
            <img src='/beast-arabic-kuth.gif' width='50px' alt="swap image" />
          </Box>
            <Box sx={{ 
              display: 'flex', 
              justifyContent: 'center', 
              alignItems: 'center', 
              mt: 2,
              mb: 2 
            }}>
            <img src={`https://io.codesperfect.com/swaps/${viewID}.png`} style={{maxHeight:300}}/>
            </Box>
            <Button style={{margin:'10px'}} variant='contained' onClick={(e)=>{window.location.href=`/view/${viewID}`}} ><RemoveRedEye></RemoveRedEye>View</Button> <DownloadButton imageUrl={`https://io.codesperfect.com/swaps/${viewID}.png`} />
          
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Awesome 🥳, Follow me at <a href='https://linkedin.com/in/codesperfect' target='_blank'>Linkedin</a>  <a href='https://github.com/codesperfect' target='_blank'>Github</a>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}
