import { Alert, Box, Button, Card, CardActions, CardContent, CardMedia, Container, Grid, ImageList, ImageListItem, Typography, useMediaQuery } from '@mui/material';
import '../App.css';
import logo from '../logo.svg';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { Redirect, useHistory, useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import DownloadButton from '../Component/DownloadButton';
import { ArrowBack, CopyAll } from '@mui/icons-material';

const View = ()=>{
  const location = useLocation();
  const {swapid} = useParams();
  
    return <div>
            <div className="App">
      <header className="App-header">
      <Button style={{color:'white'}} onClick={(e)=>{window.location.href='/'}}><ArrowBack></ArrowBack> Home</Button>
         <Container>
         <Grid container spacing={1}>
         <Grid item xs={12} sm={6} md={6} >
         <Card >
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                          <CardContent>
                            <CardMedia
                                component="img"
                                
                                src={`https://io.codesperfect.com/swaps/${swapid}.png`}
                            />
                            </CardContent>
                        </Box>
                    </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} style={{marginBottom:'20px'}}>
                    <Alert severity="error">Warning! This website may close any time! If you want this phtot download it now!</Alert><br></br><br></br>
                    <DownloadButton imageUrl={`https://io.codesperfect.com/swaps/${swapid}.png`} /><br></br><br></br>(OR)<br></br><br></br>
                    Copy and Share <Button onClick={(e)=>{navigator.clipboard.writeText(`https://thalapathy.ddns.net/view/${swapid}`);}} style={{color:'white'}}><CopyAll></CopyAll> Copy Link </Button><br></br>
                    
                    </Grid>
                    </Grid>
          
            
         </Container>
      </header>
    </div>
    </div>
}

export default View;