import './App.css';
import { BrowserRouter as Router, Routes, Route,Switch } from 'react-router-dom';
import HomePage from './pages/Home';
import Swap from './pages/Swap';
import View from './pages/View';
import Footer from './Component/Footer';
import { Alert, Button, Container, Typography } from '@mui/material';
import Logo from './Component/Logo';




function App() {
  return (
    <div>
    <div className='flex w-full' >
    {/* <img src={'logo.png'} style={{margin:'30px',width:'150px'}} variant='contained' onClick={(e)=>{window.location.href='/'}}/>
    <img src={'logo2.png'} style={{marginLeft:'30px',width:'300px'}} variant='contained' onClick={(e)=>{window.location.href='/'}}/> */}
    <Logo/>
    </div>
    <Router>
      <Switch>
        <Route path="/" exact component={HomePage} />
        <Route path="/swap/:id" exact component={Swap} />
        <Route path="/view/:swapid" exact component={View} />
      </Switch>
    </Router>
    <div>
      
    </div>
    </div>
  )
}

export default App;
