import { Alert, Avatar, Backdrop, Box, Button, Card, CardContent, CardMedia, CircularProgress, Container, Grid, IconButton, ImageList, ListItem, Snackbar, Switch, ThemeProvider, Typography, createTheme } from "@mui/material"
import { useParams } from 'react-router-dom';
import { ArrowBack, Close, TagFaces, Upload, UploadFile } from "@mui/icons-material";
import { useState } from "react";
import { MuiFileInput } from 'mui-file-input'
import { blue, red } from "@mui/material/colors";
import ModalView from '../Component/ModalView';
import * as React from 'react';

export default  () =>{
    const {id} = useParams();
    const [open, setOpen] = React.useState(false);
    const [modalOpen,setModalOpen] = React.useState(false);
    const [message,setMessage] = useState('Message');
    const [viewID,setViewID ] = useState('');
    const [openDrop, setOpenDrop] = React.useState(false);

    const handleCloseDrop = () => {
      setOpenDrop(false);
    };
    const handleOpenDrop = () => {
      setOpenDrop(true);
    };

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  
    const theme = createTheme({
        components: {
          MuiButton: {
            variants: [
              {
                props: { variant: 'dashed' },
                style: {
                  textTransform: 'none',
                  border: `2px dashed ${blue[500]}`,
                },
              },
              {
                props: { variant: 'dashed', color: 'secondary' },
                style: {
                  border: `4px dashed ${red[500]}`,
                },
              },
            ],
          },
        },
      });

      const action = (
        <React.Fragment>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <Close fontSize="small" />
          </IconButton>
        </React.Fragment>
      );
    const [file, setFile] = useState(null);
    const [preview, setPreview] = useState(null);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);

    // Create a preview of the image
    const reader = new FileReader();
    reader.readAsDataURL(selectedFile);
    reader.onloadend = () => {
      setPreview(reader.result);
    };
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log('hoh',open);
    if (!file) {

      setOpen(true);
      setMessage('Please upload the photo first!')
      return;
    }
    setOpenDrop(true);

    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await fetch(`https://io.codesperfect.com/upload/${id}`, {
        method: 'POST',
        body: formData,
      });
      setOpenDrop(false);
      if (response.ok) {
        // alert('File uploaded successfully!');
        // setLoadOpen(false);
        const data = await response.json();
        if (data.error){
          setMessage(data.error);
          setOpen(true);
        }else{
          setViewID(data.id);
          setModalOpen(true);
        }
      } else {
        // setLoadOpen(false);
        setOpen(true);

      }
    } catch (error) {
      setMessage('File upload failed!');
      setOpen(true);
    }
  };
      
      
    return (
        <div className="App">
            <header className="App-header" style={{padding:'2px'}} >
            <Button style={{color:'white'}} onClick={(e)=>{window.location.href='/'}}><ArrowBack></ArrowBack> Home</Button>
            <Container >
            <Grid container spacing={1}>
                <Grid item xs={12} sm={6} md={6} >
                    <Card >
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                          <CardContent>
                          <Typography component="div" variant="h5">
                                Thalapathy🔥
                            </Typography>
                            <Typography variant="subtitle1" color="text.secondary" component="div">
                                Upload your face by clicking upload Button
                            </Typography>
                            <CardMedia
                                component="img"
                                sx={{ maxHeight:600 }}
                                src={`http://io.codesperfect.com/images/${id}.png`}
                            />
                            </CardContent>
                        </Box>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={6} style={{marginBottom:'20px'}}>
                    <Card >
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>

                          <CardContent  >   
                            <input
                            accept="image/*"
                            onChange={handleFileChange}
                            style={{ display: 'none' }}
                            id="raised-button-file"
                            type="file"
                            />
                            <ThemeProvider theme={theme}>
                                <Button variant="dashed" color="secondary" size="large" sx={{ m: 1 }} onClick={() => document.getElementById('raised-button-file').click()}>
                                    Click here to Upload Your Face 😎!
                                </Button>
                            </ThemeProvider>
                            {preview && 
                            <CardMedia
                            component="img"
                            sx={{ maxHeight:600 }}
                            src={preview}
                        />
                            }
                            
                            <Snackbar
                              open={open}
                              autoHideDuration={6000}
                              onClose={handleClose}
                              message={message}
                              action={action}
                              anchorOrigin={{vertical:'top',horizontal:'center'}}
                            />
                            <Button onClick={handleSubmit} variant="contained" style={{marginTop:'10px',marginBottom:'10px'}}>
                            <TagFaces></TagFaces> &nbsp;&nbsp;Swap Face
                            </Button>
                            <Alert severity="error" style={{margin:'10px'}}>Photo should only contains single person! Photo should not contain multiple faces!</Alert>
                            </CardContent>
                        </Box>
                    </Card>
                </Grid>
                </Grid>

                <ModalView modalOpen={modalOpen} setModalOpen={setModalOpen} viewID={viewID} >

                </ModalView>

                <Backdrop
                  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                  open={openDrop}
                  // onClick={handleClose}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
                
            </Container>
            </header>
        </div>
    )
}