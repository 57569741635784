import { Alert, Button, Card, CardActions, CardContent, CardMedia, Container, Grid, ImageList, ImageListItem, Typography, useMediaQuery } from '@mui/material';
import '../App.css';
import logo from '../logo.svg';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { Redirect, useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const HomePage = () => {
    const [imageFilenames, setImageFilenames] = useState([]);
    const isLargeScreen = useMediaQuery('(min-width:600px)');
    const columns = isLargeScreen ? 3 : 1;
  
    
    useEffect(() => {
      const fetchImages = async () => {
        try {
          const response = await axios.get('https://io.codesperfect.com/list_images');
          setImageFilenames(response.data);
        } catch (error) {
          console.error('Error fetching images:', error);
        }
      };

      fetchImages();
    }, []);



    return (
            <div className="App">
              <header className="App-header" style={{padding:'0px'}}>
              {/* <CardMedia
              style={{borderRadius:'20px',width:'50%'}}
                component="img"
                image="logo2.png"
                alt="green iguana"
              /> */}
                {/* <Typography><h2>Happy Birthday Thalapathy🔥</h2></Typography>
                <sub>Choose thalapathy version for your face😎</sub> */}
                <Container>
                <ImageList  cols={columns} variant="standard"  >
                  {imageFilenames.map((filename, index) => (
                    <ImageListItem  key={index} onClick={(e)=>{window.location.href=`/swap/${filename.replace('.png','')}`}} style={{cursor:'pointer',paddingBottom:'10px'}}>
                      <img
                        src={`https://io.codesperfect.com/images/${filename}`}
                        loading="lazy"
                      />
                    </ImageListItem>
                  ))}
                </ImageList>
                </Container>
              </header>
            </div>
          );
}

export default HomePage;