import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#000000' : '#000',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function Logo() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={3}>
        <Grid item xs>
          <Item></Item>
        </Grid>
        <Grid item xs={6}>
          <Item><div className={'block'}>
      {/* <img src={'/logo.png'} style={{marginTop:'2px',width:'50px'}} variant='contained' onClick={(e)=>{window.location.href='/'}}/><br></br> */}
      <img src={'/logo2.png'} style={{marginTop:'2px',width:'150px'}} variant='contained' onClick={(e)=>{window.location.href='/'}}/>
      </div></Item>
        </Grid>
        <Grid item xs>
          <Item></Item>
        </Grid>
      </Grid>
    </Box>
  );
}
